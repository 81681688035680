@import '../../Shared/styles/colors.scss';

.tournament-link {
  color: $dark;
  transition: color 0.3s ease;

  &:hover {
    color: $brown;
  }
}

.organization-link {
  color: $red-dark;
  transition: color 0.3s ease;

  &:hover {
    color: $red-light;
  }
}

.social-icon:hover {
  background-color: #5e5f5e63;
  border-radius: 100%;
}

.social-icon {
  border-radius: 100%;
  cursor: pointer;
  padding: 1.2rem;
  transition: background-color 0.3s ease;
}

.level {
  .level-left,
  .level-right {
    flex: 0 1 auto;
  }

  .level-left {
    .level-item {
      max-width: fit-content;
    }
  }
}
